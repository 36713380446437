.header-wrapper .navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 80px;
  min-width: 100%;
  z-index: 1000;
  background: transparent;
  transition: background-color 0.5s ease;
}

.logo {
  margin-left: 50px;
  width: 25%;
}

.header-wrapper .navigation .menu-list {
  list-style: none;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  margin: auto 50px auto 0;
}

.pricing{
  position: relative;
}

.header-wrapper .navigation .hamburger {
  display: none;
  z-index: 11000;
}

.header-wrapper .navigation .hamburger .bar {
  width: 25px;
  height: 2px;
  margin: 5px;
  background: #FFFFFF;
}

.header-wrapper .navigation .menu-list li a {
  color: #D3D3D3;
  font-size: 1.25rem;
  letter-spacing: 2px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.5s ease;
}

.header-wrapper .navigation .menu-list li a:hover {
  color: white;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper .navigation .menu-list li a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .header-wrapper .navigation {
    height: 60px;
  }
  .logo {
    margin-left: 20px;
    width: 100%;
  }

  .header-wrapper .navigation .menu-list {
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 100%;
    background: rgba(15, 20, 35, 0.99);
    text-align: center;
    display: none;
    padding: 0;
    padding-bottom:25px;
  }

  .header-wrapper .navigation .menu-list.open {
    display: block;
  }

  .header-wrapper .navigation .menu-list li {
    display: block;
  }

  .header-wrapper .navigation .menu-list li a {
    display: block;
    padding: 20px;
    transition: color 1s ease, padding 1s ease, background-color 1s ease;
  }

  .header-wrapper .navigation .menu-list li a:hover {
    color: #56289f;
    padding-left: 30px;
    background: rgba(177, 212, 224, 0.2);
  }

  .header-wrapper .navigation .hamburger {
    display: block;
    z-index: 11000;
  }
}

.button {
  color: white;
  background-color: #56289f;
  font-size: 1.3rem;
  font-weight: 700;
  border: 2px solid hsla(0, 0%, 100%, 1);
  max-width: max-content;
  padding: 8px 20px;
  border-radius: 10px;
  transition: 0.25s ease;
  will-change: transform;
}

.button:is(:hover, :focus-visible) {
  transform: translateY(-4px);
  background-color: white;
  color: #56289f;
}

.button-container-h {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper.scrolled .navigation {
  background: #56289f;
}

@media (max-width: 599px) {

  .header-wrapper .navigation .menu-list li a {
    font-size: 17px;
  }
  .overlapping-issue {
    margin-bottom: 60px;
  }
  .logo {
    transform: scale(0.7);
    margin-left: -40px;
  }
  .button {
    font-size: 1rem;
    padding: 6px 16px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .button {
    font-size: 1.1rem;
    padding: 7px 18px;
  }
  .logo {
    transform: scale(0.8);
    margin-left: -40px;
    width: 100%;
  }
  .header-wrapper .navigation .menu-list {
    display: none;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .button {
    font-size: 1.2rem;
    padding: 8px 20px;
  }
  .logo {
    transform: scale(0.7);
    margin-left: -30px;
    width: 38%;
  }
  .header-wrapper .navigation .menu-list {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .button {
    font-size: 1.3rem;
    padding: 8px 20px;
  }
  .logo {
    transform: scale(0.9);
    margin-left: 10px;
    width: 35%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1338px) {
  .header-wrapper .navigation .menu-list {
    width: 60%;
  }
}