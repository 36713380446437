
.pricing {
  font-family: 'Roboto', sans-serif;
}

.main-p {
  padding: 100px 0;
  background-image: url(./../../images/ai-slideshow-maker-hero-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  margin: 0px;
}

.main-p::after {
  content: "";
  margin: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #2b0076 0%, #7939be 100%);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.90;
  z-index: -1;
}

.container-p {
  width: 100%;
  margin: 0px;
  max-width: 1400px;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 15px;
}

.section-intro h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}

.section-style {
  width: 130px;
  height: 12px;
  margin: 20px auto 0;
  position: relative;
}

.section-style::before {
  content: "";
  width: 80px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  margin: 0px;
  left: 0;
}

.section-style::after {
  content: "";
  width: 80px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0px;
}

.pricing-table__wrapper {
  position: relative;
  padding-top: 35px;
  margin: 0px;
}

.pricing-card-ps {
  width: 100%;
  top: 0;
  left: 0;
  margin: 0px;
}

.row {
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card-p {
  margin: 0px;
  width: 360px;
  margin: 20px;
  background-color: #faf9ff;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 2px 2px rgba(40, 25, 114, 0.1);
  transition: all 0.3s;
}

.card-p .card-p-pricing__header {
  padding: 10px 10px;
  margin: 0px;
  border-bottom: 1px solid #ebebeb;
}

.card-p .card-p-pricing__header h4 {
  margin-bottom: 5px;
  color: #1d2441;
  font-size: 27px;
  font-weight: 900;
  margin: 10px;
}

.card-p .card-p-pricing__header p {
  margin-bottom: 10px;
  color: #000000;
  font-size: 16px;
  margin: 10px;
}

.card-p-pricing__price {
  font-size: 60px;
  display: flex;
  justify-content: center;
  margin: 0px;
}

.sign {
  font-size: 40px;
  position: relative;
  bottom: 5px;
  vertical-align: middle;
}

.card-p-pricing__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 10px;
  margin-right: 40px;
  margin: 0 10px;
  list-style: none;
  border-bottom: 1px solid #ebebeb;
}
.pricing-icon{
  margin-top: 5px;
  margin-right: 6px;
  color: #56289f;
}
.text-list{
 text-align: left;
}
.bold-list {
  color: #56289f;
  font-weight: bold;
  font-size: 17px;
  text-align: left;
}

.card-p-pricing__list li {
  margin-bottom: 12px;
  color: #000000;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8;
  display: flex;
  margin-right: 10px;
 
}

.card-p-pricing__footer {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.card-p-pricing__footer .button-p {
  padding: 15px 30px;
  color: #000000;
  background: transparent;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid #dbd9e6;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.payment-text {
  font-size: 20px;
}

.card-p:hover {
  background: #ffffff;
  box-shadow: 0 20px 25px 5px rgba(33, 20, 106, 0.1);
}

.card-p:hover .card-p-pricing__header h4,
.card-p:hover .card-p-pricing__header p,
.card-p:hover .card-p-pricing__price {
  color: #56289f;
}

.card-p:hover .card-p-pricing__footer .button-p {
  color: #faf9ff;
  background: linear-gradient(to right, #2b0076 0%, #7939be 100%);
}

@media screen and (max-width: 1200px) {


  .container-p {
    padding: 0 10px;
  }

  .card-p {
    width: 100%;
    margin: 10px 0;
  }

  .pricing-table__wrapper {
    padding-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .section-intro h2 {
    font-size: 28px;
  }

  .pricing-card-ps {
    padding: 0 10px;
  }

  .card-p .card-p-pricing__header h4 {
    font-size: 22px;
  }

  .card-p .card-p-pricing__header p {
    font-size: 14px;
  }

  .card-p-pricing__price {
    font-size: 40px;
  }

  .sign {
    font-size: 24px;
  }

  .card-p-pricing__list {
    padding: 20px 5px;
  }

  .payment-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .pricing {
    margin-top: -30px;
  }



  .bold-list {
    font-size: 16px;
  }

  .section-style {
    width: 100%;
  }

  .section-style::before,
  .section-style::after {
    width: 50%;
  }

  .card-p {
    width: 100%;
  }

  .card-p-pricing__list li {
    font-size: 14px;
  }

  .card-p-pricing__footer .button-p {
    padding: 10px 20px;
    font-size: 14px;
  }
}
