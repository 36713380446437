@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

.alert {
    width: 580px;
    height: auto;
    margin: 15px 0px;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1px;
    box-sizing: border-box;
}

.alert:before {
    padding-right: 15px;
}

.alert-info {
    color: #00529B;
    background-color: #BDE5F8;
    border: 1px solid darken(#BDE5F8, 15%);
}

.alert-info:before {
    content: '\f05a';
    font-family: 'FontAwesome';
    font-size: 1.5rem;
}

.alert-error {
    color: #D8000C;
    background-color: #FFBABA;
    border: 1px solid darken(#FFBABA, 15%);
}

.alert-error:before {
    content: '\f057';
    font-family: 'FontAwesome';
    font-size: 1.2rem;
}

.alert-success {
    color: #4F8A10;
    background-color: #DFF2BF;
    border: 1px solid darken(#DFF2BF, 15%);
}

.alert-success:before {
    content: '\f058';
    font-family: 'FontAwesome';
    font-size: 1.2rem;
}

@media (max-width: 599px) {
    .alert {
        width: 90%;
        font-size: 0.8em;
        padding: 12px;
        height: auto;
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .alert {
        width: 90%;
        font-size: 0.7rem;
        padding: 15px;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {
    .alert {
        width: 380px;
        font-size: 0.9rem;
        padding: 18px;
    }
}
@media (min-width: 1000px) and (max-width: 1099px) {
    .alert {
        width: 340px;
        font-size: 0.8rem;
        padding: 16px;
    }
}
@media (min-width: 900px) and (max-width: 1000px) {
    .alert {
        width: 270px;
        font-size: 0.75rem;
        padding: 12px;
    }
}


@media (min-width: 1200px) {
    .alert {
        width: 550px;
        font-size: 1.3rem;
        padding: 20px;
    }
}
@media screen and (min-width: 1338px) and (max-width: 1511px) {
    .alert {
        width: 470px;
        font-size: 1.1rem;
        padding: 20px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1338px) {

    .alert {
        width: 400px;
        font-size: 1.0rem;
        padding: 20px;
    }
}