.contact-container {
  max-width: 600px;
  margin: 100px auto 50px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-container h2 {
  font-size: 1.8rem;
  color: #56289f;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  font-size: 1rem;
  color: #555;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  padding: 8px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
  color: #333;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #56289f;
  outline: none;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #56289f;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.contact-form button:hover {
  background-color: #56289f;
}

.contact-form button:focus {
  outline: none;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

@media (max-width: 768px) {
  .contact-container {
    max-width: 90%;
      padding: 15px;
  }

  .contact-container h2 {
      font-size: 1.6rem;
      margin-bottom: 15px;
  }

  .contact-form label {
      font-size: 0.95rem;
  }

  .contact-form input,
  .contact-form textarea {
      font-size: 0.9rem;
  }

  .contact-form button {
      font-size: 0.95rem;
      padding: 8px 18px;
  }
}

@media (max-width: 480px) {
  .contact-container {
      max-width: 90%;
      padding: 10px;
  }

  .contact-container h2 {
      font-size: 1.4rem;
      margin-bottom: 10px;
  }

  .contact-form input,
  .contact-form textarea {
      font-size: 0.85rem;
  }

  .contact-form button {
      font-size: 0.9rem;
      padding: 8px 15px;
  }
}
.result-message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  width: 100%;
}

.result-message.success {
  background-color: #4caf50;
  color: white;
  border: 2px solid #388e3c;
}

.result-message.error {
  background-color: #f44336;
  color: white;
  border: 2px solid #d32f2f;
}

.result-message.info {
  background-color: #2196f3;
  color: white;
  border: 2px solid #1976d2;
}
