.about {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-right: 30px;
  padding-left: 30px;

 
  font-family: 'Roboto', sans-serif;
}

.container-about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-banner img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
 
}

.feature-content {
  padding-bottom: 25px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section-subtitle {
  font-size: 1.6em;
  margin-bottom: -10px;
  color: #56289f;
  font-weight: bold;
  width: 100%;
  
  
}

.section-title-about {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}

.section-text {
  font-size: 1.2em;
  line-height: 1.8;
  margin-bottom: 10px;
  width: 100%;
}

.feature-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.feature-list li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.feature-card {
  display: flex;
  align-items: center;
}

.card-icon {
  font-size: 2em;
  margin-right: 20px;
}

.span {
  font-size: 1.5em;
}

.bold-text {
  color: #56289f;
}

@media screen and (min-width: 1338px) and (max-width: 1511px) {

  .feature-content {
    text-align: left;
  }

  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 2.5em;
  }
}

@media (max-width: 576px) {
  .section-title-about {
    font-size: 1.5em;
    margin-bottom: -5px;
    margin-top: 20px;
  }
  
  .about {
    padding-right: 20px;
    padding-left: 20px;
    
  }

  .section-text {
    font-size: 0.8em;
  }

  .feature-list {
    padding: 0;
  }

  .card-icon {
 
    
    font-size: 1.5em;
  }

  .feature-banner img {
    max-width: 100%;
    height: auto;
    display: block;
    
    margin: 0 auto;
  }
}

@media (max-width:  599px) {
  .about {
    padding-top: 10px;
  }
  .feature-content {
    margin-bottom: -30px;
  }

  .feature-banner img {
    max-width: 130%;
    height: auto;
    margin-left: -35px;
    margin-top: 20px;
  }
  .span {
    font-size: 1em;
    
  }
  

.section-subtitle {
  font-size: 1.1em;

  
}
  
}


@media screen and (min-width: 1200px) and (max-width: 3000px) {
  .feature-content {
    text-align: left;
  }

  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 2.0em;
  }
  .section-subtitle {
    font-size: 1.4em;
   
  }
  
  .section-title-about {
    font-size: 2.2em;
   
  }
  
  .section-text {
    font-size: 1.1em;
  
  }
  .span {
    font-size: 1.3em;
  }
  
}
@media (min-width:1100px) and (max-width: 1199px) {
  .feature-content {
    text-align: left;
  }
  .about {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 2em;
  }
  .section-subtitle {
    font-size: 1.2em;
   
  }
  
  .section-title-about {
    font-size: 2em;
   
  }
  
  .section-text {
    font-size: 1em;
  
  }
  .span {
    font-size: 1.2em;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .feature-content {
    text-align: left;
  }
  .about {
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 1.6em;
  }
  .section-subtitle {
    font-size: 1em;
   
  }
  
  .section-title-about {
    font-size: 1.8em;
   
  }
  
  .section-text {
    font-size: 0.8em;
  
  }
  .span {
    font-size: 1em;
  }
}
@media (min-width: 900px) and (max-width: 1000px) {
  .feature-content {
    text-align: left;
  }
  .about {
    padding-top: 40px;
    padding-bottom: 0px;
  }
  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 1.5em;
  }
  .section-subtitle {
    font-size: 0.9em;
   
  }
  
  .section-title-about {
    font-size: 1.7em;
   
  }
  
  .section-text {
    font-size: 0.7em;
  
  }
  .span {
    font-size: 0.9em;
  }
}

@media (min-width: 800px) and (max-width: 899px) {
  .feature-content {
    text-align: left;
  }
  .about {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .container-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .feature-banner {
    flex: 1;
  }

  .feature-content {
    flex: 1;
    margin-top: 0;
  }

  .card-icon {
    font-size: 1.4em;
  }
  .section-subtitle {
    font-size: 0.8em;
   
  }
  
  .section-title-about {
    font-size: 1.6em;
   
  }
  
  .section-text {
    font-size: 0.6em;
  
  }
  .span {
    font-size: 0.8em;
  }
}
