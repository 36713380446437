.radio-input {
  position: relative;
  background: linear-gradient(135deg, rgb(179, 208, 253) 0%, rgb(164, 202, 248) 100%);
  border-radius: 15px;
  padding: 10px;
  display: grid;
  place-content: center;
  z-index: 0;
  width: 370px;
  height: 120px;
  margin: 0 10px;
}








.container-t {
  position: relative;
  background: linear-gradient(135deg, rgb(179, 208, 253) 0%, rgb(164, 202, 248) 100%);
  border-radius: 15px;
  padding: 10px;
  display: grid;
  place-content: center;
  z-index: 0;
  margin: 0 10px;
  width: 370px;
  height: 120px;
}

.container-t::after,
.container-t::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
}

.container-t::before {
  top: -1px;
  left: -1px;
  width: 350px;
  height: 100px;
  background: linear-gradient(0deg, rgb(218, 232, 247) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}

.container-t::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(0deg, rgb(163, 206, 255) 0%, rgb(211, 232, 255) 100%);
  box-shadow: rgba(79, 156, 232, 0.7019607843) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7019607843) 5px 5px 20px 0px;
  z-index: -2;
}

.container-t {
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 350px;
  height: 100px;
  border-radius: 15px;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  padding: 8px 25px;
  display: flex;
  align-items: center;
}

.text-colors-btn {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
  text-shadow: 2px 2px 5px white;
}

.radio-input input[type=radio] {
  display: none;
}

.radio-input input[type=radio] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.radio-input input[type=radio] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid WHITE;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
}

.radio-input input[type=radio] + label span svg {
  opacity: 0;
  transition: all 0.3s ease;
  margin-top: 4px;
}

.radio-input input[type=radio]#color-1 + label span {
  background-color: #56289f;
}

.radio-input input[type=radio]#color-2 + label span {
  background-color: #000000;
}

.radio-input input[type=radio]#color-3 + label span {
  background-color: #ffffff;
}

.radio-input input[type=radio]#color-4 + label span {
  background-color: #003366;
}

.radio-input input[type=radio]#color-5 + label span {
  background-color: #ADD8E6;
}

.radio-input input[type=radio]#color-6 + label span {
  background-color: #D3D3D3;
}

.radio-input input[type=radio]:checked + label span svg {
  opacity: 1;
}

.radio-input:has(input[type=radio]#color-1:checked) {
  background-color: #56289f;
}

.radio-input:has(input[type=radio]#color-2:checked) {
  background-color: #000000;
}

.radio-input:has(input[type=radio]#color-3:checked) {
  background-color: #ffffff;
}

.radio-input:has(input[type=radio]#color-4:checked) {
  background-color: #003366;
}

.radio-input:has(input[type=radio]#color-5:checked) {
  background-color: #ADD8E6;
}

.radio-input:has(input[type=radio]#color-6:checked) {
  background-color: #D3D3D3;
}
