@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tomorrow:wght@400;700&display=swap');





.hero-content{
             display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            
}




.headline-container {
  width: 100%; 
  height: 100%; 
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden; 
}

.headline-text {
 
  position: relative;
 
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 5px;

  
  font-family: 'Poppins', sans-serif;
  z-index: 1;
  overflow: hidden; 
}





.hero-img{
 
 width: 145%;
  height: auto;
  margin-top: -120px;
  margin-left: -40px;
  
 } 

 .hero-form{
  
 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  margin-top: -150px;
  margin-left: 18px;
  width: 100%;
 }
 




.downloadbtn{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;
  gap: 50px;
}
.share-div{
 display: flex;
  justify-content: center;
}


.hero{
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: url(./../../images/ai-slideshow-maker-hero-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

  font-family: Arial, sans-serif;
  
  width: 100%;
  height: 100%;

  
}
.alert-container{
  display: flex;
  justify-content: center;
}
.notice-box ul{
  gap: 5px; 
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  margin-bottom: -5px;
  font-size: 14px ;
}
.notice-box{
 


  line-height: 1.5;
  text-transform: uppercase;
  gap: 5px; 

  display: flex;
  justify-content: center;
 align-items: center;
}
.hero::after {
  content: "";
  margin :0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #2b0076 0%, #7939be 100%);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
  z-index: -1;
}






.bt {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  
}


.note{
 
 
  color: white;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  
}





.customize{
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 50px;
  
  
}
.customize-2{
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 50px;
}
.customize-3{
  display: flex;
 margin-top: 90px;
  justify-content: center;
  margin-bottom: -60px;
  
}

.slides-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.slide {
  width: 10in;
  height: 5.625in;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
}


.slide-title {
  position: absolute;
  top: 0.27in;
  left: 1in;
 
 
  text-align: center;
  font-size: 26pt;
  width: 8in;
  font-weight: bold;
}


.images {
  position: absolute;
  top: 1.2in; 
  left: 0.5in; 
  width: 5in; 
  height: 3in;
}


.paragraph {

  top: 4.6in; 
  left: 0.4in; 
  font-size: 11pt;
  width: 90%;
  position: absolute;
  line-height: 1.1;
}


.bullet-points {

  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5in;
  top: 1.4in; 
  left: 5.4in; 
  font-size: 15pt; 
  width: 4.5in;
font-style: italic;
  
}

/* HORIZONTAL LINE */
.horizontal-line {
  position: absolute;
  top: 4.5in; 
  left: 0.4in; 
  width: 9.2in; 
  height: 0.08in;
 
}





.hero-right-text{
  position: relative;
  top:-50px;
  margin-right: 20px;
}

.container-s{
  position: relative;
  background: linear-gradient(135deg, rgb(179, 208, 253) 0%, rgb(164, 202, 248) 100%);
  border-radius: 1000px;
  padding: 10px;
  display: grid;
  place-content: center;
  z-index: 0;
  max-width: 300px;
  margin: 0 10px;
}

.search-container {
  position: relative;
 
  border-radius: 50px;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  padding: 5px;
  display: flex;
  align-items: center;
}

.search-container::after,
.search-container::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
}

.search-container::before {
  top: -1px;
  left: -1px;
  background: linear-gradient(0deg, rgb(218, 232, 247) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}

.search-container::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(0deg, rgb(163, 206, 255) 0%, rgb(211, 232, 255) 100%);
  box-shadow: rgba(79, 156, 232, 0.7019607843) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7019607843) 5px 5px 20px 0px;
  z-index: -2;
}

.input {
  padding: 10px;
  width: 100%;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  border: none;
  color: black;
  font-size: 20px;
  border-radius: 50px;
}

.input:focus {
  outline: none;
  background: linear-gradient(135deg, rgb(239, 247, 255) 0%, rgb(214, 229, 247) 100%);
}
.outputt{
  display: flex;
  justify-content: center;
}
.search__icon {
  width: 50px;
  aspect-ratio: 1;
  border-left: 2px solid white;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  padding-left: 12px;
  margin-right: 10px;
}





.search__icon:hover {
  border-left: 3px solid white;
}

.search__icon path {
  fill: white;
}



.btn3 {
  --clr-font-main: hsla(0 0% 20% / 100);
  --btn-bg-1: hsla(330 80% 60% / 1);
  --btn-bg-2: hsla(260 70% 60% / 1);
  --btn-bg-color: hsla(0 0% 100% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 0.6em 1em;
 
  width: 350px; 
 height: auto;
 
  font-size: 25px;
  box-sizing: border-box; 
  font-family: "Segoe UI", system-ui, sans-serif;
  font-weight: 500;
  transition: background 0.8s, transform 0.3s; 
  border: none;
  border-radius: var(--radii);
  color: var(--btn-bg-color);
  background: linear-gradient(90deg, rgb(235, 71, 153) 35%, hsla(260 70% 60% / 1) 100%);
  box-shadow: 0px 0px 20px rgba(255, 105, 180, 0.5), 0px 5px 5px -1px rgba(138, 43, 226, 0.25), inset 4px 4px 8px rgba(255, 182, 193, 0.5), inset -4px -4px 8px rgba(148, 0, 211, 0.35);
  position: relative;
  overflow: hidden;
  display: flex; 
  align-items: center; 
  gap: 8px; 
  justify-content: center; 
}

.btn3:hover, .btn3:focus-visible {
  border: 2px solid #ffffff;
  transform: scale(1.05);
}

.btn3::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 70%);
  top: 0;
  left: -100px;
  opacity: 0.4;
  transition: opacity 0.3s ease;
  z-index: 1;
  animation: shine 1.5s ease-out infinite; 
}

.btn-text {
  margin-bottom: 4px;
}

.btn3:hover::before {
  opacity: 0.8; 
}

@keyframes shine {
  0% {
    left: -100px;
  }
  100% {
    left: 100%;
  }
}

.loading-animation{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 300px;
  transform: scale(2);
  
}@media (max-width: 599px) {

  
.notice-box{
  margin-bottom: -160px;
   margin-top: 150px;
 
  line-height: 1.5;
  text-transform: uppercase;
  

  display: flex;
  flex-direction: column;
}
.notice-box ul{
  font-size: 7px ;
  margin-bottom: -5px;
   margin-top: -35px;
  
}
  .btn3:hover {
   
    transform: scale(1);
  }
  .headline-text {
    font-size: 23px;
    margin-bottom: 20px;
    margin-top: -4px;
    padding-bottom: 20px; 
  }
  .customize {
    transform: scale(0.32);
    margin-top: -20px;
  }
  .customize-2 {
    transform: scale(0.32);
    margin: -100px;
    margin-bottom: -120px;
  }
  .customize-3{
    transform: scale(0.32);
    margin: 70px;
    margin-bottom: -120px;
    
  }
  .loading-animation {
    transform: scale(1.5);
  }
  .hero {
    padding-bottom: 0px; 
  }
  .hero-content {
    flex-direction: column;
    align-items: center; 
  }
  .hero-img-container {
    margin-bottom: 20px; 
  }
  .hero-img {
    width: 80%; 
    margin: 0;
    margin-left: -40px;
    margin-top: -50px;
  }
  .hero-form {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 20px; 
    margin: 0; 
    margin-bottom: 20px;
    margin-top: 20px;
    transform: translateY(-235px);
  }
  .hero-right-text {
    text-align: center;
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -260px;
    top: -270px;
  }
 
  .slides-container {
    transform: scale(0.38);
    margin: -180px 20px -300px 20px;
  }
  .downloadbtn {
    margin-top: 110px;
    flex-direction: column;
    transform: scale(0.5);
    gap: 30px;
    margin-bottom: -65px;
  }
  .btn3 {
    transform: scale(0.9);
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {

  .hero-img {
    width:160%;
    }
    .headline-text {
    font-size: 40px;
    margin-bottom: 60px;
  }
  .btn3 {
    font-size: 22px;
    padding: 0.6em 1.2em;
    min-width: 120px;
  }

}
@media (min-width: 900px) and (max-width: 1000px) {
 
 .notice-box ul{
   font-size: 10px ;

 }
  .hero-img {
    width:155%;
    }
    .container-s {

      min-width: 300px;  
      
     }
     .search-container {
       padding: 10px;  
       width: 280px;
     }
     .input {
      
       font-size: 20px;
      
     }
     .search__icon {
       width: 50px;  
     }
    .headline-text {
    font-size: 40px;
    margin-bottom: 60px;
  }
  .btn3 {
    font-size: 18px;
    padding: 0.4em 1em;
    width: 270px;
  }
 
}
@media (min-width:1100px) and (max-width: 1199px) {
  .headline-text {
    font-size: 50px;
    margin-bottom: 50px;
  }

 .notice-box ul{
   font-size: 10px ;
 
   
 }
  .hero-img {
  width:145%;
  }
  .btn3 {
    font-size: 22px;
    padding: 0.6em 1.2em;
    min-width: 120px;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  
  
  .customize {
    transform: scale(0.8);
    margin-top: 20px;
  }
  .customize-2 {
    transform: scale(0.8);
    margin: -20px;
    margin-bottom: -10px;
  }
  .customize-3 {
    transform: scale(0.8);
    margin: 30px;
    margin-bottom: -10px;
  }
 
 
  .slides-container {
    transform: scale(0.9);
    margin: 10px 20px 10px 20px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .headline-text {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .notice-box{
    
     margin-top: 40px;
   
  }
  .notice-box ul{
    font-size: 10px ;
  
    
  }
  .hero-content {
    flex-direction: column;
    align-items: center;
  }
  .hero-img-container {
    margin-bottom: 20px;
  }
  .hero-img {
    width: 65%;
    margin: 0;
    margin-left: -40px;
    margin-top: -50px;
  }
  .hero-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0;
    margin-bottom: 80px;
    margin-top: -80px;
    transform: translateY(-250px);
  }
  .hero-right-text {
    text-align: center;
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -260px;
    top: -270px;
  }
 
  .customize {
    transform: scale(0.6);
  }
  .customize-2 {
    transform: scale(0.6);
    margin: -60px;
    margin-bottom: 40px;
  }
  .customize-3 {
    transform: scale(0.6);
    margin: -50px;
    margin-bottom: 40px;
  }
  .slides-container {
    transform: scale(0.7);
    margin: -100px 20px -100px 20px;
  }
  .downloadbtn {
    margin-top: 70px;
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .alert-container {
    margin-top: 20px;
  }
  .headline-text {
    font-size: 70px;
    margin: 0 60px;
    
  }
  .downloadbtn {
    margin-top: 70px;
  }
  .slides-container {
    transform: scale(1.2);
    margin: 180px 20px 100px 20px;
  }
  .customize {
    margin-top: 40px;
  }
  .customize-2 {
    margin-bottom: -50px;
  }
  .customize-3 {
    margin-bottom: -50px;
  }
  .btn3 {
    font-size: 25px;
    padding: 0.6em 1em;
    min-width: 120px;
  }
  
.container-s {

 min-width: 500px;  
 
}
.search-container {
  padding: 10px;  
  width: 480px;
}
.input {
 
  font-size: 25px;
 
}
.search__icon {
  width: 60px;  
}
 
}
@media screen and (min-width: 1338px) and (max-width: 1511px) {

 
  .headline-text {
    font-size: 60px;
    margin: 0 60px 20px;
    
  }
  
.container-s {

 min-width: 400px;  
 
}
.search-container {
  padding: 10px;  
  width: 380px;
}
.input {
 
  font-size: 20px;
 
}
.search__icon {
  width: 40px;  
}
.hero-img {
  width: 130%;
}

}
@media screen and (min-width: 1200px) and (max-width: 1338px) {
  .headline-text {
    font-size: 50px;
    margin: 0 60px 40px;
    
  }

 .notice-box ul{
   font-size: 12px ;
 
   
 }

  .hero-img {
    width: 140%;
  }
  
.container-s {

  min-width: 370px;  
  
 }
 .search-container {
   padding: 10px;  
   width: 350px;
 }
 .input {
  
   font-size: 18px;
  
 }
 .search__icon {
   width: 35px;  
 }
  

}