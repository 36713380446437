.footer-f {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 2rem;
  background-color: #000000;
  padding-top: 50px;
  padding-bottom: 50px;
}

.company {
  font-size: 1rem;
  line-height: 1.5;
  color: #aaa;
  padding-left: 5%;
}

.footer-brand {
  padding-left: 5%;
  padding-top: 45px;
}

.footer-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #aaa;
}

.social-list {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
  padding-left: 5%;
  list-style: none;
}

.social-link {
  width: 40px;
  height: 40px;
  background-color: #56289f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: background-color 0.3s;
}

.social-link:hover {
  background-color: #ab93f2;
}

.twitter-id {
  color: #aaa;
  text-decoration: none;
}

.footer-list {
  padding-left: 45%;
  list-style: none;
}

.footer-list-title {
  font-size: 1.875rem;
  margin-bottom: 15px;
  color: #FFFFFF;
}

.footer-link {
  display: block;
  font-size: 1rem;
  color: #aaa;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s;
  cursor: pointer;
}

.footer-link:hover {
  color: #ab93f2;
}

address.footer-text {
  font-style: normal;
  margin-bottom: 10px;
}

.butn {
  padding-top: 45px;
  padding-left: 32%;
}

@media screen and (max-width: 1024px) {
  .view {
      grid-template-columns: repeat(2, 1fr);
  }

  .company-name {
    margin-left: 90px;
  }

  .view.grid-list {
      padding: 1rem;
      padding-bottom: 50px; 
  }

  .footer-list,
  .butn {
      padding-left: 0;
      text-align: center;
  }

  .social-list {
      margin: 0 auto;
      justify-content: center;
  }

  .company {
    padding-left: 150px;
  }
}

@media screen and (max-width: 768px) {
  .company-name {
    margin-left: 10px;
  }

  .view {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .view.grid-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 50px;
  }

  .footer-list,
  .butn {
      padding-left: 0;
      text-align: center;
  }

  .social-list {
      margin-right: 0;
      justify-content: center;
  }

  .company {
    padding-left: 100px;
  }
}

@media screen and (max-width: 599px) {
  .view.grid-list {
      padding: 0.5rem;
      padding-bottom: 30px; 
  }

  .footer-list {
      padding-left: 0;
      font-size: 0.875rem;
  }

  .footer-list-title {
      font-size: 1.5rem;
  }

  .footer-link {
      font-size: 0.875rem;
  }

  .butn {
      padding-left: 0;
  }

  .social-list {
      padding-left: 0;
      margin-left: -20px;
  }

  .company {
    padding-left: 50px;
  }
}
