.main-upload {
    position: relative;
    background: linear-gradient(135deg, rgb(179, 208, 253) 0%, rgb(164, 202, 248) 100%);
    border-radius: 15px;
    padding: 10px;
    display: grid;
    place-content: center;
    z-index: 0;
    width: 300px;
    height: 100px;
    margin: 0 10px;
  }
  
  .container-upload::after,
  .container-upload::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 15px;
    position: absolute;
  }
  
  .container-upload::before {
    top: -1px;
    left: -1px;
    width: 280px;
    background: linear-gradient(0deg, rgb(218, 232, 247) 0%, rgb(255, 255, 255) 100%);
    z-index: -1;
  }
  
  .container-upload::after {
    bottom: -1px;
    right: -1px;
    background: linear-gradient(0deg, rgb(163, 206, 255) 0%, rgb(211, 232, 255) 100%);
    box-shadow: rgba(79, 156, 232, 0.7) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7) 5px 5px 20px 0px;
    z-index: -2;
  }
  
  .container-upload {
    flex-direction: column;
    position: relative;
    width: 280px;
    height: 80px;
    border-radius: 15px;
    background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
    padding: 8px 25px;
    display: flex;
    align-items: center;
  }
  
  .text-upload {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 5px white;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    align-items: center;
  }
  
  .upload-picker {
    display: none; /* Hide the default file input */
  }
  
  .custom-upload-button {
    padding: 8px 18px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: black;
  background-color: transparent;
    border: none;
    border-radius: 10px;
   
    cursor: pointer;
  
  }
  
  
  
  .custom-upload-button:active {
    transform: scale(0.98);
  }
  
  .uploaded-image-preview {
    display: flex;
    align-items: center;
    margin-top: -90px;
    margin-left: 600px;
  }
  
  .uploaded-image-preview img {
    max-width: 180px;
    max-height: 180px;
    border-radius: 10px;
  }
  
  .uploaded-image-preview button {
    margin-left: 10px;
    padding: 4px 9px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    border: none;
    border-radius: 50%;
    background-color: #000000;
  }
  