.share-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.url-box {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  max-width: 600px;
  width: 100%;
}

.url-box span {
  font-size: 18px;
  margin-right: 12px;
  color: #333;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.share-button {
  background-color: #56289f;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.1s ease-in-out;
}

.share-button svg {
  margin-right: 8px;
}

.share-button:hover {
  background-color: #eb4799;
  transform: translateY(-2px);
}

.share-button:active {
  background-color: #d83787;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .url-box {
    padding: 14px 20px;
  }

  .url-box span {
    font-size: 16px;
  }

  .share-button {
    font-size: 16px;
    padding: 12px 18px;
    margin-left: 12px;
  }
}

@media (max-width: 480px) {
  .url-box {
    padding: 12px 16px;
  }

  .url-box span {
    font-size: 14px;
  }

  .share-button {
    font-size: 16px;
    padding: 10px 15px;
    margin-left: 12px;
    width: 150px;
    height: 50px;
  }
}
