@import url('https://fonts.googleapis.com/css?family=Berkshire+Swash|Raleway|Poppins|Dancing+Script|Cinzel|Josefin+Sans|Abril+Fatface|Quicksand|Fjalla+One|Amatic+SC|Montserrat|Great+Vibes|Cabin|Satisfy|Bree+Serif|Permanent+Marker|Cookie|Merienda|Yellowtail|Alfa+Slab+One|Caveat|Baloo+2|Gloria+Hallelujah|Luckiest+Guy|Rock+Salt&display=swap');

.main-f {
    position: relative;
    background: linear-gradient(135deg, rgb(179, 208, 253) 0%, rgb(164, 202, 248) 100%);
    border-radius: 15px;
    padding: 10px;
    display: grid;
    place-content: center;
    z-index: 0;
    width: 300px;
    height: 100px;
    margin: 0 10px;
}

.container-f::after,
.container-f::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
}

.container-f::before {
  top: -1px;
  left: -1px;
  width: 280px;
  background: linear-gradient(0deg, rgb(218, 232, 247) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}

.container-f::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(0deg, rgb(163, 206, 255) 0%, rgb(211, 232, 255) 100%);
  box-shadow: rgba(79, 156, 232, 0.7019607843) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7019607843) 5px 5px 20px 0px;
  z-index: -2;
}

.container-f {
  flex-direction: column;
  position: relative;
  width: 280px;
  height: 80px;
  border-radius: 15px;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  padding: 8px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
}

.font-picker {
  padding: 10px;
  width: 100%;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  border: none;
  color: black;
  font-size: 20px;
  border-radius: 50px;
  appearance: none;
  background: url('../../images/down-arrow.png') no-repeat right center;
  background-size: 20px;
  outline: none;
}

.font-picker:focus {
  outline: none;
  box-shadow: none;
}

.text-f {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 5px white;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  align-items: center;
}
