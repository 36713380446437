/* General Overlay Style */
.buy-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

/* Modal Styling */
.buy-modal-content {
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  width: 700px;
  max-width: 90%;
  position: relative;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.4s ease-in-out;
}

/* Close Button */
.buy-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  color: #333;
  cursor: pointer;
}


.buy-modal-title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
}



/* Feature List */
.buy-modal-features {
  text-align: left;
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

.buy-modal-features li {
  font-size: 16px;
  color: #444;
  margin: 10px 0;
  padding-left: 30px;
  position: relative;
}

.buy-modal-features li::before {
  content: "✔";
  color: #4caf50;
  font-weight: bold;
  position: absolute;
  left: 10px;
  top: 0;
}

/* Pricing Section */
.buy-modal-pricing {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  position: relative;
}

.buy-modal-option {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.buy-modal-single-download {
  border: 2px solid #4caf50;
  position: relative;
}

.buy-modal-option h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  margin-top: 5px;
}

.buy-modal-description {
  font-size: 15px;
  color: #555;
  margin-bottom: 15px;
}

.buy-modal-price {
  font-size: 28px;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 20px;
}
.buy-modal-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 14px 30px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.buy-modal-button:hover {
  background-color: #3e8e41; /* More prominent darker green */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25); /* Slightly stronger shadow */
}


/* Star Badge */
.buy-modal-stars {
  position: absolute;
  top: -15px;
  right: -10px;
  background-color: transparent;
  color: #ffd700;
  font-size: 22px;
  font-weight: bold;
  z-index: 10;
}

/* Footer Section */
.buy-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buy-modal-contact {
  font-size: 16px;
  color: #555;
}

.buy-modal-arrow {
  color: black;
}

.buy-modal-contact a {
  color: #007bff;
  text-decoration: none;
}

.buy-modal-contact a:hover {
  text-decoration: underline;
}



.buy-modal-badge {
  width: 200px;
  height: auto;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .buy-modal-content {
    width: 90%;
    padding: 25px;
  }
  .buy-modal-close {
    top: -4px; 
    right: 4px; 
  }

  .buy-modal-pricing {
    flex-direction: column;
    margin-bottom: -40px;
    margin-top: 0px;
  }

  .buy-modal-option {
    width: 100%;
    margin-bottom: 20px;
  }

  .buy-modal-footer {
    flex-direction: column;
    gap: 2px;
  }
}
/* General Responsive Enhancements */
@media (max-width: 768px) {
  .buy-modal-content {
    width: 90%;
    padding: 20px;
    font-size: 14px;
  }

  .buy-modal-title {
    font-size: 24px;
  }

  .buy-modal-features li {
    font-size: 14px;
  }

  .buy-modal-price {
    font-size: 24px;
  }

  .buy-modal-button {
    font-size: 16px;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .buy-modal-content {
    width: 95%;
    padding: 15px;
  }

  .buy-modal-title {
    font-size: 20px;
  }

  .buy-modal-features li {
    font-size: 12px;
  }

  .buy-modal-price {
    font-size: 20px;
  }

  .buy-modal-button {
    font-size: 14px;
    padding: 10px 15px;
  }

  .buy-modal-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .buy-modal-contact {
    font-size: 14px;
  }

  .buy-modal-badge {
    width: 150px;
  }
}
