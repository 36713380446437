.intro-section {
  padding: 85px 40px 10px;
  background: linear-gradient(to right, #2b0076 0%, #7939be 100%);
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.tagline {
  font-size: 25px;
  color: white;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease, text-shadow 0.3s ease;
}

.tagline:hover {
  text-decoration: underline;
}

.presentation-topic {
  font-size: 50px;
  font-weight: 800;
  font-family: 'Roboto', sans-serif;
  color: black;
  text-align: center;
  padding-top: 30px;
  line-height: 1.3;
  letter-spacing: 1px;
  text-transform: capitalize;
  max-width: 80%;
  margin: 0 auto;
}

@media (max-width: 599px) {
  .intro-section {
    padding: 60px 20px 10px;
  }

  .tagline {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .presentation-topic {
    font-size: 24px;
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .intro-section {
    padding: 70px 30px 10px;
  }

  .tagline {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .presentation-topic {
    font-size: 30px;
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  .intro-section {
    padding: 60px 35px 8px;
  }

  .tagline {
    font-size: 15px;
    margin-bottom: 12px;
  }

  .presentation-topic {
    font-size: 30px;
    padding-top: 12px;
    max-width: 85%;
  }
}
