.glitch {
    position: relative;
    font-size: 100px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    font-family: 'Tomorrow', sans-serif;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
 }
 
 .glitch:before,
 .glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
 }
 
 .glitch:before {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #0ff;
    z-index: -1;
 }
 
 .glitch:after {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #f0f;
    z-index: -2;
 }
 
 @keyframes glitch-color {
    0% {
        transform: translate(0);
    }
 
    20% {
        transform: translate(-3px, 3px);
    }
 
    40% {
        transform: translate(-3px, -3px);
    }
 
    60% {
        transform: translate(3px, 3px);
    }
 
    80% {
        transform: translate(3px, -3px);
    }
 
    to {
        transform: translate(0);
    }
 }
 