.faq-main {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.wrapper-faq {
  width: 70%;
}

.heading-faq {
  margin-bottom: 10px;
  color: #56289f;
  font-size: 40px;
  margin-top: 20px;
  text-align: center;
}

.text-faq {
  margin: 30px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-faq > span {
  margin-top: 40px;
  color: #000000;
  font-size: 25px;
  font-weight: 600;
}

.container-faq {
  background-color: white;
  color: #000000;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgb(0,0,0,0.25);
  margin: 20px 0;
}

.question {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px 80px 20px 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.question::after {
  content: "\002B";
  color: #56289f;
  font-size: 2.2rem;
  position: absolute;
  right: 20px;
  transition: 0.2s;
}

.question.active::after {
  transform: rotate(45deg);
}

.answercont {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.answer {
  padding: 0 20px 20px;
  line-height: 1.5rem;
}



@media screen and (max-width: 790px) {
  .faq-main {
    font-size: 14px;
  }
  .wrapper-faq {
    width: 80%;
  }
  .text-faq > span {
    font-size: 15px;
  }
  .heading-faq {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

@media (max-width: 599px) {
  .faq-main {
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .text-faq {
    margin-bottom: 10px;
  }

  .question {
    padding: 15px 40px 15px 20px;
    font-size: 1rem;
  }

  .answer {
    padding: 0 15px 15px;
    font-size: 0.85rem;
  }

  .heading-faq {
    font-size: 18px;
  }

  .text-faq > span {
    font-size: 12px;
    margin-top: 20px;
  }
}
