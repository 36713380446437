.share-info {
  margin-bottom: 70px;
  padding-top: 1px;
  margin-top: -40px;
}

.prompt-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px;
}
.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.prompt-content {
  background: linear-gradient(135deg, #ffffff 0%, #f9f9f9 100%);
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
}

.prompt-content h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

.prompt-content textarea {
  width: 100%;
  padding: 15px 16px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
  height: 80px;
  resize: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.prompt-content input:focus {
  border-color: #4682B4;
  outline: none;
}

.prompt-content button {
  margin: 8px;
  padding: 10px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.prompt-content button:first-of-type {
  background-color: #4682B4;
  color: #fff;
}

.prompt-content button:last-of-type {
  background-color: #ff6347;
  color: #fff;
}

.prompt-content button:hover {
  transform: scale(1.05);
}

.prompt-content button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 599px) {
  .prompt-content {
      padding: 15px;
      border-radius: 10px;
  }

  .prompt-content input {
      padding: 10px;
      font-size: 0.9rem;
  }

  .prompt-content button {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}

.images {
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.images:hover {
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.7);
  transform: scale(1.05);
}

.images.auto-hover {
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.7);
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 19%;
  left: 16.5%;
  transform: translate(-50%, -50%);
  display: none;
}
.firstphoto{
  position: absolute;
  width: 4in;
  height: 5.625in;
}

.images:hover + .image-overlay {
  display: block;
}

.images.auto-hover + .image-overlay {
  display: block;
}

.image-label {
  background-color: rgba(0, 204, 255, 0.85);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  box-shadow: 0 0 15px rgba(0, 204, 255, 0.8);
  transition: opacity 0.3s ease;
  text-transform: uppercase;
}

.loading-spinner {
  position: absolute;
  top: 45%;
  left: 30%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #00ccff;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 10;
  animation: fadeIn 0.5s ease, pulse 1.5s infinite alternate;
}

.magic-icon {
  font-size: 36px;
  animation: pulseMagic 1.5s infinite alternate;
}

.loading-text {
  font-size: 18px;
  color: #00ccff;
  letter-spacing: 1px;
  animation: pulseText 1.5s infinite alternate;
}

@keyframes pulseMagic {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.2);
      opacity: 0.7;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

@keyframes pulseText {
  0% {
      opacity: 0.7;
      text-shadow: 0 0 5px rgba(0, 204, 255, 0.7);
  }
  50% {
      opacity: 1;
      text-shadow: 0 0 20px rgba(0, 204, 255, 0.8), 0 0 30px rgba(0, 204, 255, 0.6);
  }
  100% {
      opacity: 0.7;
      text-shadow: 0 0 5px rgba(0, 204, 255, 0.7);
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.note-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; /* Black background */
  padding: 10px;
  border-radius: 8px;
  position: absolute; /* Keep it positioned relative to its parent */
  bottom: 20px; /* Adjust this value as needed */
  left:1%;
  bottom:550px;
  z-index: 1000; /* Ensure it appears above other content */
  width: auto;
}

.note-text {
  color: white;
  font-size: 16px;
  margin-right: 10px;
}

.icon {
  color: white;
  font-size: 20px;
  cursor: pointer;
}
